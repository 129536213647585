import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import BlockTextImage from '../../components/ACF/BlockTextImage/BlockTextImage'
import BlockIcons from '../../components/ACF/BlockIcons/BlockIcons'
import { getBlockTextImageContent, getIconBlocksContent } from './data'

const KeyServicesPageTemplate = ({ data }) => {
	const brandName = data.brand.name

	const keyData = {
		seo: {
			canonical: `/autosleutels/${data.brand.slug}`,
			cornerstone: false,
			focuskw: `autosleutels ${brandName}`,
			metaDesc: `Wilt u een extra autosleutel voor uw ${brandName} of heeft u een probleem met uw autosleutel van uw ${brandName}? `,
			metaKeywords: `autosleutels ${brandName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `autosleutels ${brandName} - ECU adaptions`,
			title: `autosleutels ${brandName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: data?.brand?.name,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	const ctaData = { callToAction: data.wpCtaPost }

	const keyServicesTextImageContent = getBlockTextImageContent(brandName)
	const keyServicesIconBlocksContent = getIconBlocksContent(brandName)

	return (
		<PageLayout pageData={keyData}>
			<BlockTextImage data={keyServicesTextImageContent} generated={true} />
			<BlockIcons data={keyServicesIconBlocksContent} generated={true} />
			<BlockCallToAction data={ctaData} />
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: ${props => props.padding};
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex: 3;
	margin-right: 3rem;
	margin-bottom: 3rem;
`

const FeaturesContainer = styled.div`
	flex: 2;
	margin-bottom: 3rem;
`

const Title = styled.h1`
	margin: 0;
	margin-bottom: 3rem;
`

const TextTitle = styled.h5`
	margin: 0;
	margin-bottom: 1rem;
	max-width: 600px;
`

const Text = styled.p`
	margin: 0;
	margin-bottom: 1.5rem;
`

const Notice = styled.p`
	margin: 0;
	font-style: italic;
	color: rgb(129, 129, 129);
`

const FeatureItem = styled.p`
	display: flex;
	flex-direction: row;
	margin: 0;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	align-items: center;
`

export default KeyServicesPageTemplate

export const query = graphql`
	query KeyServiceBrandQuery($id: String!) {
		brand(id: { eq: $id }) {
			id
			name
			slug
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		wpCtaPost(slug: { eq: "offerte-aanvragen" }) {
			id
			title
			postContent {
				buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
			}
		}
	}
`
