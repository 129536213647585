export const getBlockTextImageContent = brandName => {
	return {
		__typename: 'WpPage_PageComponents_Components_BlockTextImage',
		imagePosition: 'left',
		linkOrButton: null,
		active: null,
		linkButtonText: null,
		paddingBottom: 40,
		paddingTop: 80,
		text: `<p>Wilt u een extra autosleutel voor uw ${brandName} of heeft u een probleem met uw autosleutel? Wij bieden u de service om een nieuwe sleutel bij te laten maken. Door onze jarenlange ervaring leveren wij betrouwbare autosleutels van hoge kwaliteit voor een betaalbare prijs.</p>\n`,
		title: '<span class="accented">Ecu adaptions</span> sleutelservice',
		subtitle: `Professionele ${brandName} autosleutels van hoge kwaliteit`,
		titleColor: null,
		link: null,
		backgroundShape: null,
		image: {
			sourceUrl: 'https://cms.ecuadaptions.nl/wp-content/uploads/2021/12/minikeys.jpg',
			localFile: {
				childImageSharp: {
					gatsbyImageData: {
						layout: 'constrained',
						backgroundColor: '#080808',
						images: {
							fallback: {
								src: '/static/acf006a680afb818ad555bf080b5f6b8/ee717/minikeys.jpg',
								srcSet:
									'/static/acf006a680afb818ad555bf080b5f6b8/3381a/minikeys.jpg 200w,\n/static/acf006a680afb818ad555bf080b5f6b8/475ce/minikeys.jpg 400w,\n/static/acf006a680afb818ad555bf080b5f6b8/ee717/minikeys.jpg 800w',
								sizes: '(min-width: 800px) 800px, 100vw'
							},
							sources: [
								{
									srcSet:
										'/static/acf006a680afb818ad555bf080b5f6b8/310b7/minikeys.webp 200w,\n/static/acf006a680afb818ad555bf080b5f6b8/42553/minikeys.webp 400w,\n/static/acf006a680afb818ad555bf080b5f6b8/ed336/minikeys.webp 800w',
									type: 'image/webp',
									sizes: '(min-width: 800px) 800px, 100vw'
								}
							]
						},
						width: 800,
						height: 713
					}
				}
			}
		}
	}
}

export const getIconBlocksContent = brandName => {
	return {
		__typename: 'WpPage_PageComponents_Components_BlockIcons',
		paddingTop: 40,
		paddingBottom: 80,
		backgroundColor: '#f7f7f7',
		blocks: [
			{
				blockIconsIcon: 'gear',
				blockIconsTitle: 'Behuizing',
				blockIconsContent: `Wanneer de behuizing van de autosleutel van uw ${brandName} is beschadigd of aan vervanging toe is kunt u ook bij ons terecht.`
			},
			{
				blockIconsIcon: 'key',
				blockIconsTitle: 'Reparatie',
				blockIconsContent: `Is uw ${brandName} sleutel afgebroken of is de baard van de sleutel beschadigd. Geen probleem! Kom langs en wij repareren uw sleutel!`
			},
			{
				blockIconsIcon: 'angles-up',
				blockIconsTitle: 'Afstandsbediening',
				blockIconsContent: `Werkt uw ${brandName} afstandsbediening niet meer of wilt u een extra afstandsbediening.`
			},
			{
				blockIconsIcon: 'code',
				blockIconsTitle: 'Programmeren',
				blockIconsContent: `Wij kunnen alle ${brandName} sleutels inleren. Als u een sleutel kwijt bent geraakt kunnen we deze uitschakelen.`
			}
		]
	}
}
